<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="cons">
        <div class="qkdh-left">
          <div class="qkdh-left-dafenlei">
            <div
              :class="item.class"
              @click="clickdafenlei(item.code, key)"
              v-for="(item, key) in dafenleilist"
              :key="key"
              :title="item.name"
            >
              <div class="qkdh-left-dafenlei-list-text">{{ item.name }}</div>
              <div class="qkdh-left-dafenlei-list-num">({{ item.qknum }})</div>
            </div>
          </div>
        </div>
        <div class="qkdh-right">
          <div class="qkdh-right-shaixuan">
            <div class="qkdh-right-shaixuan-list">
              <div class="qkdh-right-shaixuan-list-title">刊首字母：</div>
              <div class="qkdh-right-shaixuan-list-con-all">
                <el-checkbox
                  border
                  size="small"
                  label="全选"
                  v-model="kanshouzimuall"
                  @change="clickkanshouzimuall"
                  >全选</el-checkbox
                >
              </div>
              <div class="qkdh-right-shaixuan-list-con">
                <el-checkbox-group v-model="kanshouzimu" size="small">
                  <el-checkbox
                    v-for="(item, key) in kanshouzimulist"
                    :key="key"
                    :label="item"
                    border
                    @change="clickkanshouzimu"
                    >{{ item }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="qkdh-right-shaixuan-list" v-show="isshaixuan">
              <div class="qkdh-right-shaixuan-list-title">核心收录：</div>
              <div class="qkdh-right-shaixuan-list-con-all">
                <el-checkbox
                  border
                  size="small"
                  label="全选"
                  v-model="hexinshouluall"
                  @change="clickhexinshouluall"
                  >全选</el-checkbox
                >
              </div>
              <div class="qkdh-right-shaixuan-list-con">
                <el-checkbox-group v-model="hexinshoulu" size="small">
                  <el-checkbox
                    v-for="(item, key) in hexinshoululist"
                    :key="key"
                    :label="item.key"
                    border
                    @change="clickhexinshoulu"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="qkdh-right-shaixuan-list" v-show="isshaixuan">
              <div class="qkdh-right-shaixuan-list-title">收录地区：</div>
              <div class="qkdh-right-shaixuan-list-con-all">
                <el-checkbox
                  border
                  size="small"
                  label="全选"
                  v-model="shouludiquall"
                  @change="clickshouludiquall"
                  >全选</el-checkbox
                >
              </div>
              <div class="qkdh-right-shaixuan-list-con">
                <el-checkbox-group v-model="shouludiqu" size="small">
                  <el-checkbox
                    v-for="(item, key) in shouludiqulist"
                    :key="key"
                    :label="item.code"
                    border
                    @change="clickshouludiqu"
                    >{{ item.sf }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="qkdh-right-shaixuan-list" v-show="isshaixuan">
              <div class="qkdh-right-shaixuan-list-title">出版周期：</div>
              <div class="qkdh-right-shaixuan-list-con-all">
                <el-checkbox
                  border
                  size="small"
                  label="全选"
                  v-model="chubanzhouqiall"
                  @change="clickchubanzhouqiall"
                  >全选</el-checkbox
                >
              </div>
              <div class="qkdh-right-shaixuan-list-con">
                <el-checkbox-group v-model="chubanzhouqi" size="small">
                  <el-checkbox
                    v-for="(item, key) in chubanzhouqilist"
                    :key="key"
                    :label="item.key"
                    border
                    @change="clickchubanzhouqi"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="qkdh-right-shaixuan-list" v-show="isshaixuan">
              <div class="qkdh-right-shaixuan-list-title">数据库收录：</div>
              <div class="qkdh-right-shaixuan-list-con-all">
                <el-checkbox
                  border
                  size="small"
                  label="全选"
                  v-model="qikanshouluall"
                  @change="clickqikanshouluall"
                  >全选</el-checkbox
                >
              </div>
              <div class="qkdh-right-shaixuan-list-con">
                <el-checkbox-group v-model="qikanshoulu" size="small">
                  <el-checkbox
                    v-for="(item, key) in qikanshoululist"
                    :key="key"
                    :label="item.key"
                    border
                    @change="clickqikanshoulu"
                    >{{ item.label }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
            <div class="qkdh-right-shaixuan-more">
              <div
                class="qkdh-right-shaixuan-more-text"
                @click="isshaixuan = !isshaixuan"
              >
                {{ isshaixuan ? "精简选项-" : "更多选项+" }}
              </div>
            </div>
          </div>
          <div class="qkdh-right-paixu">
            <div class="qkdh-right-paixu-list">
              <div class="qkdh-right-paixu-list-model">
                <div class="qkdh-right-paixu-list-model-title">排序：</div>
                <div class="qkdh-right-paixu-list-model-select">
                  <el-select
                    v-model="paixuvalue"
                    class="m-2"
                    placeholder="Select"
                    size="small"
                    @change="clickpaixu"
                  >
                    <el-option
                      v-for="item in paixulist"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>
              <div class="qkdh-right-paixu-list-tongji">
                共找到<span>{{ totals }}</span
                >本期刊
              </div>
            </div>
          </div>
          <div class="qkdh-right-con">
            <div
              class="qkdh-right-con-list"
              v-for="(item, key) in qikanlist"
              :key="key"
            >
              <router-link
                target="_blank"
                :to="{ name: '/xkzs/del', query: { id: item.Id } }"
              >
                <el-image
                  :src="proxy.$imgurl + '/qikanfmpic/' + item.qkcode + '.jpg'"
                  style="
                    width: 158px;
                    height: 208px;
                    cursor: pointer;
                    border: 1px solid #ccc;
                  "
                  alt=""
                  fit="fill"
                />
              </router-link>
              <div class="qkdh-right-con-list-name">
                {{ item.qkname }}
              </div>
              <div class="qkdh-right-con-list-hexin">
                <div
                  class="qkdh-right-con-list-hexin-list"
                  v-for="(e, i) in item.qikanshoulu"
                  :key="i"
                >
                  {{ e }}
                </div>
              </div>
            </div>
          </div>
          <div class="qkdh-right-fy">
            <el-pagination
              small
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[20, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totals"
              @size-change="handleSizeChange"
              @current-change="fanye"
            />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 显示更多
let isshaixuan = ref(true);
// 列表数据
let qikanlist = reactive([]);

// 分页
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(20);

// 排序
let paixuvalue = ref("Id");
const paixulist = [
  {
    label: "综合排序",
    value: "Id",
  },
  {
    label: "影响因子",
    value: "yxyz",
  },
];

// 刊首字母
let kanshouzimu = ref([]);
let kanshouzimuall = ref(false);
let submitkanshouzimu = ref(""); //提交的数据
const kanshouzimulist = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// 核心收录
let hexinshoulu = ref([]);
let hexinshouluall = ref(false);
let submithexinshoulu = ref(""); //提交的数据
const hexinshoululist = [
  {
    label: "CSTPCD",
    key: "CSTPCD",
  },
  {
    label: "北大核心",
    key: "北大核心",
  },
  {
    label: "CSSCI",
    key: "CSSCI",
  },
  {
    label: "SCI",
    key: "SCI",
  },
  {
    label: "EI",
    key: "EI",
  },
  {
    label: "CSCD",
    key: "CSCD",
  },
  {
    label: "普刊",
    key: "普刊",
  },
];

// 收录地区
let shouludiqu = ref([]);
let shouludiquall = ref(false);
let submitshouludiqu = ref(""); //提交的数据
const shouludiqulist = [
  { sf: "北京", code: "10" },
  { sf: "天津", code: "12" },
  { sf: "河北", code: "13" },
  { sf: "山西", code: "14" },
  { sf: "内蒙古", code: "15" },
  { sf: "辽宁", code: "21" },
  { sf: "吉林", code: "22" },
  { sf: "黑龙江", code: "23" },
  { sf: "上海", code: "31" },
  { sf: "江苏", code: "32" },
  { sf: "浙江", code: "33" },
  { sf: "安徽", code: "34" },
  { sf: "福建", code: "35" },
  { sf: "江西", code: "36" },
  { sf: "山东", code: "37" },
  { sf: "河南", code: "41" },
  { sf: "湖北", code: "42" },
  { sf: "湖南", code: "43" },
  { sf: "广东", code: "44" },
  { sf: "广西", code: "45" },
  { sf: "海南", code: "46" },
  { sf: "重庆", code: "50" },
  { sf: "四川", code: "51" },
  { sf: "贵州", code: "52" },
  { sf: "云南", code: "53" },
  { sf: "西藏", code: "54" },
  { sf: "陕西", code: "61" },
  { sf: "甘肃", code: "62" },
  { sf: "青海", code: "63" },
  { sf: "宁夏", code: "64" },
  { sf: "新疆", code: "65" },
  { sf: "台湾", code: "71" },
  { sf: "香港", code: "81" },
  { sf: "澳门", code: "82" },
  { sf: "其他", code: "00" },
];

// 出版周期
let chubanzhouqi = ref([]);
let chubanzhouqiall = ref(false);
let submitchubanzhouqi = ref(""); //提交的数据
const chubanzhouqilist = [
  {
    label: "年刊",
    key: "年刊",
  },
  {
    label: "半年刊",
    key: "半年刊",
  },
  {
    label: "季刊",
    key: "季刊",
  },
  {
    label: "双月刊",
    key: "双月刊",
  },
  {
    label: "月刊",
    key: "月刊",
  },
  {
    label: "半月刊",
    key: "半月刊",
  },
  {
    label: "旬刊",
    key: "旬刊",
  },
  {
    label: "双周刊",
    key: "双周刊",
  },
  {
    label: "周刊",
    key: "周刊",
  },
  {
    label: "周二刊",
    key: "周二刊",
  },
];

// 期刊收录
let qikanshoulu = ref([]);
let qikanshouluall = ref(false);
let submitqikanshoulu = ref(""); //提交的数据
const qikanshoululist = [
  {
    label: "知网",
    key: "知网",
  },
  {
    label: "维普",
    key: "维普",
  },
  {
    label: "万方",
    key: "万方",
  },
];

// 大分类
let dafenleilist = reactive([]);
let flg = ref("");

// 获取大分类
const getdafenlei = () => {
  get("/Journal/GetQkdhZtflhCount").then((res) => {
    // console.log(res.data)
    dafenleilist.length = 0;
    let allsum = 0;
    res.data.forEach((e) => {
      allsum += e.qknum;
      dafenleilist.push(
        Object.assign(e, {
          class: "qkdh-left-dafenlei-list qkdh-left-dafenlei-list-none",
        })
      );
    });
    dafenleilist.push({
      class: "qkdh-left-dafenlei-list qkdh-left-dafenlei-list-none",
      code: "",
      name: "全部",
      qknum: allsum,
    });
    sortDownByKey(dafenleilist, "qknum");
  });
};
getdafenlei();

// 获取列表
const getlist = () => {
  post("/Journal/GetQkdhList", {
    flh: flg.value,
    kszm: submitkanshouzimu.value,
    hxsl: submithexinshoulu.value,
    sldq: submitshouludiqu.value,
    cbzq: submitchubanzhouqi.value,
    pageindex: page.value,
    pagesize: pageSize.value,
    slqk: submitqikanshoulu.value,
    order: paixuvalue.value,
  }).then((res) => {
    // console.log(res)
    qikanlist.length = 0;
    totals.value = res.total;
    res.data.forEach((e) => {
      e.qikanshoulu = e.coreperiodical.split(",");
      qikanlist.push(e);
    });
  });
};
getlist();

// 点击看守字母
const clickkanshouzimu = () => {
  kanshouzimuall.value = false;
  submitkanshouzimu.value =
    kanshouzimu.value.length > 0 ? JSON.stringify(kanshouzimu.value) : "";
  // console.log("刊首字母：" + submitkanshouzimu.value);
  getlist();
};
// 全选
const clickkanshouzimuall = () => {
  kanshouzimu.value = [];
  submitkanshouzimu.value = "";
  // console.log("刊首字母：" + submitkanshouzimu.value);
  getlist();
};

// 点击核心收录
const clickhexinshoulu = () => {
  hexinshouluall.value = false;
  submithexinshoulu.value =
    hexinshoulu.value.length > 0 ? JSON.stringify(hexinshoulu.value) : "";
  // console.log("核心收录：" + submithexinshoulu.value);
  getlist();
};
// 全选
const clickhexinshouluall = () => {
  hexinshoulu.value = [];
  submithexinshoulu.value = "";
  // console.log("核心收录：" + submithexinshoulu.value);
  getlist();
};

// 点击收录地区
const clickshouludiqu = () => {
  shouludiquall.value = false;
  submitshouludiqu.value =
    shouludiqu.value.length > 0 ? JSON.stringify(shouludiqu.value) : "";
  // console.log("收录地区：" + submitshouludiqu.value);
  getlist();
};
// 全选
const clickshouludiquall = () => {
  shouludiqu.value = [];
  submitshouludiqu.value = "";
  // console.log("收录地区：" + submitshouludiqu.value);
  getlist();
};

// 点击出版周期
const clickchubanzhouqi = () => {
  chubanzhouqiall.value = false;
  submitchubanzhouqi.value =
    chubanzhouqi.value.length > 0 ? JSON.stringify(chubanzhouqi.value) : "";
  // console.log("收录地区：" + submitchubanzhouqi.value);
  getlist();
};
// 全选
const clickchubanzhouqiall = () => {
  chubanzhouqi.value = [];
  submitchubanzhouqi.value = "";
  // console.log("收录地区：" + submitchubanzhouqi.value);
  getlist();
};

// 点击期刊收录
const clickqikanshoulu = () => {
  qikanshouluall.value = false;
  submitqikanshoulu.value =
    qikanshoulu.value.length > 0 ? JSON.stringify(qikanshoulu.value) : "";
  // console.log("期刊收录：" + submitqikanshoulu.value);
  getlist();
};
// 全选
const clickqikanshouluall = () => {
  qikanshoulu.value = [];
  submitqikanshoulu.value = "";
  // console.log("期刊收录：" + submitqikanshoulu.value);
  getlist();
};

// 点击排序
const clickpaixu = () => {
  // console.log(paixuvalue.value);
  getlist();
};

// 点击大分类
const clickdafenlei = (data, index) => {
  // 更新样式
  dafenleilist.forEach((e, i) => {
    if (index == i) {
      e.class = "qkdh-left-dafenlei-list qkdh-left-dafenlei-list-active";
    } else {
      e.class = "qkdh-left-dafenlei-list qkdh-left-dafenlei-list-none";
    }
  });
  // console.log(data)
  flg.value = data;
  getlist();
};

// 分页
const fanye = (val) => {
  // console.log(val)
  page.value = val;
  currentPage.value = val;
  getlist();
};
//切换页码
const handleSizeChange = (val) => {
  // console.log(val)
  pageSize.value = val;
  getlist();
};

// 跳转期刊
// const clicktzqk = (id) => {
//   const url = router.resolve({
//     path: "/xkzs/del",
//     query: {
//       id: id,
//     },
//   });
//   window.open(url.href);
// };

//数组对象方法排序:降序
const sortDownByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
};
</script>

<style scoped>
:deep(.el-checkbox__input) {
  display: none;
}
:deep(.el-checkbox__label) {
  padding-left: 4px;
}
:deep(.el-checkbox) {
  margin-right: 5px;
  margin-bottom: 5px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons:after {
  content: "";
  display: table;
  clear: both;
}
.qkdh-left {
  width: 240px;
  float: left;
}
.qkdh-right {
  width: 930px;
  float: right;
}
.qkdh-left-dafenlei {
  width: 100%;
  height: auto;
}
.qkdh-left-dafenlei-list {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 1px;
}
.qkdh-left-dafenlei-list-active {
  background: #cdddf1;
  color: #333;
}
.qkdh-left-dafenlei-list-none {
  background: #6a97d2;
  color: #fff;
  cursor: pointer;
}
.qkdh-left-dafenlei-list:hover {
  opacity: 0.9;
}
.qkdh-left-dafenlei-list-text {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}
.qkdh-right-shaixuan-list {
  width: 100%;
  height: auto;
}
.qkdh-right-shaixuan-list:after {
  content: "";
  display: table;
  clear: both;
}
.qkdh-right-shaixuan-list-title {
  font-size: 14px;
  font-weight: bold;
  float: left;
  width: 85px;
  height: 24px;
  line-height: 24px;
}
.qkdh-right-shaixuan-list-con {
  width: calc(100% - 145px);
  height: auto;
  float: left;
}
.qkdh-right-shaixuan-list-con-all {
  width: 60px;
  height: 24px;
  float: left;
}
.qkdh-right-shaixuan-more {
  position: relative;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  border-top: 1px solid #d4ecff;
  margin-top: 10px;
}
.qkdh-right-shaixuan-more-text {
  position: absolute;
  left: calc(50% - 65px);
  top: -1px;
  width: 130px;
  height: 30px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6c00;
  background: #f7fbff;
  box-sizing: border-box;
  border-left: 1px solid #d4ecff;
  border-right: 1px solid #d4ecff;
  border-bottom: 1px solid #d4ecff;
  cursor: pointer;
}
.qkdh-right-paixu {
  margin-top: 10px;
}
.qkdh-right-paixu-list {
  width: 100%;
  height: 40px;
  background: #f3f3f3;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.qkdh-right-paixu-list-model-title {
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
}
.qkdh-right-paixu-list-model {
  width: 200px;
  height: 22px;
  float: left;
  margin: 8px 20px 0 15px;
  display: flex;
  align-items: center;
}
.qkdh-right-paixu-list-tongji {
  width: 200px;
  height: 22px;
  float: right;
  margin: 8px 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}
.qkdh-right-paixu-list-tongji span {
  font-weight: bold;
  color: #417dc9;
  padding: 0 5px;
}
.qkdh-right-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.qkdh-right-con-list {
  width: 186px;
  min-height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 0 0;
}
.qkdh-right-con-list-name {
  width: 100%;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #417dc9;
  margin: 5px 0;
  cursor: pointer;
}
.qkdh-right-con-list-name:hover {
  color: #ff6c00;
}
.qkdh-right-con-list-hexin {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}
.qkdh-right-con-list-hexin:after {
  content: "";
  display: table;
  clear: both;
}
.qkdh-right-con-list-hexin-list {
  width: auto;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  color: #077c04;
  border: 1px solid #ccc;
  font-size: 12px;
  margin: 0 5px 5px 0;
}
.qkdh-right-fy {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
